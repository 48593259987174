import React from "react";
import { Page } from "../../../components/elements";
import { PageCenter } from "../../../components/elements/wrappers";
import { EGIconnectRequestForm } from "../../../components/forms";
import { Col, Row } from "react-bootstrap";
import { DefaultHeader } from "../../../components/elements/Headers.jsx";
import { Helmet } from "react-helmet";
import { useParams } from "../../../hooks/useParams";

export default function ContactConnectpage() {
    const {egi_number, title} = useParams();

    return (
        <Page
            title="EGIconnect Report Request Form"
            description="EGIconnect Report Request Form for EGI Corporate Associate Members."
        >
            <PageCenter bg="white">
                <DefaultHeader
                    title="EGIconnect Report Request Form for EGI Corporate Associate Members."
                    type="small"
                />
                <Row className="border-bottom pb-5">
                    <Col style={{ justifyContent: "center" }}>
                        <EGIconnectRequestForm egi_number={egi_number} title={title}/>
                    </Col>
                </Row>

                <div className="EGI_Request_to_Contact_page">
                <h4>
                <br />
                Do you have a question, inquiry or feedback for us? Fill out the form on the <a href="https://egi.utah.edu/about/contact">Contact & Map page</a> and we will respond as soon as possible.
                </h4>
                </div>
                
            </PageCenter>
 
        </Page>

        
    );
}
