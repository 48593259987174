export function useParams() {
let final = {};
if (typeof window !== "undefined") {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    urlParams.forEach((value, key) => {
    final = { ...final, [key]: value };
    });
}
return final;
}